<template>
  <div class="popup_layer">
    <div class="outter_box">
      <div class="popup_in">
        <slot name="title" />
        <div class="popup_contents">
          <slot name="contents" />
        </div>
        <slot name="btn" />
      </div>
    </div>
    <div class="bg" />
  </div>
</template>

<style scoped>
.popup_layer {display: block; width: 100%; height: 100%; position: fixed; left: 0; top: 100%; transition: top 0.05s ease-out 0.25s; z-index: 150;}
.popup_layer .bg {content: ''; width: 100%; height: 100%; position: absolute; left: 0px; top: 0px; background-color:var(--color-primary-10); opacity: 0; transition: opacity 0.3s ease-out;}
.popup_layer.open {top: 0; transition: top 0.05s ease-out 0s;}
.popup_layer.open .bg {opacity: 0.5; transition: opacity 0.5s ease-out; z-index:1;}
.popup_layer .outter_box {position:relative; z-index:2; width: 100%; height: 100%; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center;}
.popup_layer .popup_in {position:relative; width: auto; min-height:auto; padding:24px 24px 16px; position: relative; margin: auto; box-sizing: border-box; background-color: var(--color-contents-background); opacity: 0; transform: translate(0, 100px) scale(0.75, 0.75); transition: all 0.4s cubic-bezier(1,-0.74,.13,.99); border-radius:8px; }
.popup_layer.open .popup_in {transform: translate(0, 0) scale(1.0, 1.0); opacity: 1;transition: all 0.5s cubic-bezier(.59,0,0,1.63);}

.popup_layer.small_size .popup_in{width:320px;}
.popup_layer.middle_size .popup_in{width:720px;}

@media screen and (max-width: 768px) {
  .popup_layer.middle_size .popup_in{width:100%; margin: 0; padding:24px 20px 16px; border-radius:8px 8px 0 0;}
  .popup_layer .outter_box {align-items: flex-end;}
  .popup_layer .popup_in{max-height:calc(100% - 213px);}

  .popup_layer.bottom_btn .popup_in{padding-bottom:74px;}
  .popup_layer.bottom_btn .popup_contents{max-height: calc(100vh - 343px); }
  .btn_right button{width:50%;}

  .popup_layer.middle_size .popup_in{max-height:100%;}
  .popup_layer.middle_size.bottom_btn .popup_contents{max-height: 100%; }
}
</style>
